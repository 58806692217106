@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background: linear-gradient(135deg, #e0eafc, #cfdef3);
  background-attachment: fixed;
  color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}

.MuiDrawer-paper {
  width: 250px;
}

.MuiAppBar-root {
  background-color: #3f51b5;
}

.MuiTypography-h4 {
  color: #3f51b5;
}

.MuiCard-root {
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
}

.MuiCard-root:hover {
  transform: translateY(-5px);
}

.MuiPaper-root {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  margin-top: 0; /* Ensure no margin at the top */
  box-shadow: none; /* Remove shadow */
}

.container-root {
  padding: 20px;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li {
  margin-bottom: 10px;
}

ul li:last-child {
  margin-bottom: 0;
}
